import { clone } from "lodash";

import ApiService            from "@/shared/Services/ApiService.js";
import { useAuthStore }      from "@/shared/Stores/AuthStore.js";
import { jsonParseIfNeeded } from "@/shared/utils.js";

class CrudEntity {
    static _uri      = null;
    static _defaults = {};
    static _allowIds = false;
    _name            = "entity";
    /** @type {CrudEntity} _static */
    _static          = null;
    _uuid            = null;
    _data            = {};
    eventHandlers    = {};

    /**
     * @param {Object} data Data to put into object
     * @returns {CrudEntity}
     */
    constructor(data = {}) {
        const handler = {
            set(target, key, value) {
                return target.setKey(key, value);
            },
            get(target, key) {
                return target.getKey(key);
            },
        };

        let obj     = new Proxy(this, handler);
        obj._data   = clone(this.constructor._defaults);
        obj._static = this.constructor;
        data        = this._static.transformDataIfNecessary(data);

        if (data.uuid) {
            obj._uuid = data.uuid;
        }
        if (data.id && !this._static._allowIds) {
            delete data.id;
        }
        for (let key in data) {
            obj[key] = data[key];
        }

        return obj;
    }

    static isReadonly() {
        const authStore = useAuthStore();
        if (authStore?.user?.role?.level === "demo") {
            return true;
        }
        return false;
    }

    static checkCapabilities(cap) {
        const authStore = useAuthStore();
        const role      = authStore?.getUser?.role;
        let def         = jsonParseIfNeeded(role.def || "{}");
        let defs        = {};
        for (let d of def) {
            defs[d.uri] = d.capabilities;
        }
        if (!defs[this._uri]) {
            return false;
        }
        if (!cap) {
            return defs[this._uri];
        }
        if (defs[this._uri].includes(cap)) {
            return true;
        }
        return false;
    }

    static isDeletable() {
        return true;
    }

    static create(data = {}) {
        return new this(data).create();
    }

    static beforeGet(params = {}) {
        return params;
    }

    static getName() {
        return (new this)._name;
    }

    static get(uuid, _with = [], extraOptions = {}) {
        if (typeof uuid === "object") {
            if (Object.keys(uuid).includes("uuid")) {
                uuid = uuid.uuid;
            } else {
                throw new Error("UUID is required for get");
            }
        }
        let params = this.beforeGet({ uuid: uuid });
        return new Promise((resolve, reject) => {
            this.find(params, _with, undefined, undefined, undefined, extraOptions).then(({
                data,
                response,
                headers,
            }) => {
                if (data.length === 0) {
                    /* eslint-disable-next-line no-console */
                    console.error("Geen " + this.getName() + " gevonden met UUID " + uuid);
                    reject({
                        data: [],
                        response,
                        headers,
                    });
                }
                resolve({
                    data: this._resolveResponseData(data[0]),
                    response,
                    headers,
                });
            }).catch(error => reject(error));
        });
    }

    static beforeFind(params = {}, _with, _limit, _offset, _sort) {
        if (!params.with && _with.length > 0) {
            params.with = _with.join(",");
        }
        if (_sort.column) {
            params.sort = `${_sort.column}+${_sort.direction}`;
            if (_sort.column.indexOf(".") !== -1) {
                params.sort = _sort.column.replaceAll(".", "___") + `+${_sort.direction}`;
            }
        }
        if (!_offset) {
            _offset = 0;
        }
        if (!_limit) {
            _limit = 25;
        }
        if (_limit) {
            params.page  = Math.floor(_offset / _limit) + 1;
            params.limit = _limit;
        }

        return params;
    }

    static find(params = {}, _with = [], _offset = 0, _limit = 25, _sort = {
        column:    "created_at",
        direction: "desc",
    }, extraOptions    = {}) {
        if (!this._uri) {
            throw new Error("URI is not set");
        }
        params = this.beforeFind(params, _with, _limit, _offset, _sort);
        return new Promise((resolve, reject) => {
            ApiService.get(this._uri, params, {}, extraOptions)
                .then(({
                    data,
                    response,
                    headers,
                }) => {
                    resolve({
                        data: this._resolveResponseData(data, true),
                        response,
                        headers,
                    });
                })
                .catch((error) => {
                    if ((error.response && (error.response.status === 204 || error.response.status === 404))) {
                        resolve({
                            data:     [],
                            response: error.response,
                            headers:  error.response.headers,
                        });
                    } else if (error.status && error.status === 204) {
                        resolve({
                            data:     [],
                            response: error.response,
                            headers:  {},
                        });
                    } else {
                        reject(error);
                    }
                });
        });
    }

    static findTransformFunction(key) {
        let keyName           = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
        let transformFunction = `transform${keyName}`;
        if (typeof this[transformFunction] === "function") {
            return this[transformFunction];
        } else {
            return null;
        }
    }

    static transformDataIfNecessary(data) {
        for (let key in data) {
            let transformFunction = this.findTransformFunction(key);
            if (transformFunction) {
                data[key] = transformFunction(data[key]);
            }
        }
        return data;
    }

    static createFromData(data) {
        data    = this.transformDataIfNecessary(data);
        let obj = new this(data);
        // Can debug here if needed
        return obj;
    }

    static _resolveResponseData(data, forceArray = false) {
        if (data instanceof this) {
            if (forceArray) {
                return [ data ];
            }
            return data;
        }
        if (Array.isArray(data)) {
            return data.map(item => this._resolveResponseData(item));
        }
        if (forceArray && !Array.isArray(data)) {
            return [ this._resolveResponseData(data) ];
        }
        return this.createFromData(data);
    }

    setKey(key, value) {
        if (key === "uuid") {
            this._uuid = value;
            return true;
        }
        if (Object.keys(this).indexOf("" + key) !== -1 || key.startsWith("_")) {
            this[key] = value;
            return true;
        }
        this._data[key] = value;
        return true;
    }

    getKey(key) {
        if (key === "uuid") {
            return this._uuid;
        }
        if (key in this._data && typeof key === "string" && !key.startsWith("_")) {
            return this._data[key];
        }
        return this[key];
    }

    /**
     * @returns {Promise<CrudEntity|CrudEntity[]>}
     */
    save() {
        if (this._uuid) {
            return this.update();
        } else {
            return this.create();
        }
    }

    toJSON() {
        return { uuid: this._uuid, ...this.beforeSave(this._data) };
    }

    on(event, handler) {
        if (!this.eventHandlers[event]) {
            this.eventHandlers[event] = [];
        }
        this.eventHandlers[event].push(handler);
    }

    off(event, handler) {
        // Remove handler from event handlers
        if (this.eventHandlers[event]) {
            this.eventHandlers[event].splice(this.eventHandlers[event].indexOf(handler), 1);
        }
    }

    trigger(event, data = {}) {
        if (this.eventHandlers[event]) {
            this.eventHandlers[event].forEach(handler => handler(data));
        }
    }

    afterSave() {
    }

    isReadonly() {
        return this._static.isReadonly();
    }

    isDeletable() {
        return this._static.isDeletable();
    }

    beforeSave(data) {
        return clone(data);
    }

    /**
     * @returns {Promise<CrudEntity|CrudEntity[]>}
     */
    create() {
        let data = this.beforeSave(this._data);
        return new Promise((resolve, reject) => {
            ApiService.post(this._static._uri, {}, data)
                .then(({
                    data,
                    response,
                    headers,
                }) => {
                    let entities = this._resolveResponseData(data);
                    if (!Array.isArray(entities)) {
                        entities = [ entities ];
                    }

                    for (let entity of entities) {
                        if (entity.afterSave) {
                            entity.afterSave();
                        }
                    }

                    if (entities.length === 1) {
                        resolve({
                            data: entities[0],
                            response,
                            headers,
                        });
                    }
                    resolve({
                        data: entities,
                        response,
                        headers,
                    });
                })
                .catch(error => reject(error));
        });
    }

    _resolveResponseData(data, forceArray = false) {
        return this._static._resolveResponseData(data, forceArray);
    }

    beforeUpdate(uuid, data) {
        return { uuid: uuid, ...data };
    }

    /**
     * @returns {Promise<CrudEntity|CrudEntity[]>}
     */
    update(fields = {}, onlyFields = []) {
        if (!this._uuid) {
            throw new Error("UUID is required for update");
        }
        if (this.isReadonly()) {
            throw new Error("Entity is read-only");
        }
        for (let f in fields) {
            this[f] = fields[f];
        }
        let data = this.beforeUpdate(this._uuid, this._data);
        if (onlyFields.length) {
            let filteredData = {};
            for (let f of onlyFields) {
                filteredData[f] = data[f];
            }
            data = filteredData;
        }
        return new Promise((resolve, reject) => {
            ApiService.put(this._static._uri, { uuid: this._uuid }, data)
                .then(({
                    data,
                    response,
                    headers,
                }) => {
                    let entity = this._resolveResponseData(data);
                    if (entity.afterSave) {
                        entity.afterSave();
                    }

                    resolve({
                        data: this._resolveResponseData(data),
                        response,
                        headers,
                    });
                })
                .catch(error => reject(error));
        });
    }

    delete() {
        if (!this._uuid) {
            throw new Error("UUID is required for delete");
        }
        if (!this.isDeletable()) {
            throw new Error("Entity is not deletable");
        }
        if (this.isReadonly()) {
            throw new Error("Entity is read-only");
        }
        return new Promise((resolve, reject) => {
            ApiService.delete(this._static._uri, { uuid: this._uuid })
                .then(() => resolve())
                .catch(error => reject(error));
        });
    }

    /**
     * Refresh current entity from API
     * @param _with {string[]} Additional data to get
     * @returns {Promise<unknown>}
     */
    refresh(_with = []) {
        if (!this._uuid) {
            throw new Error("UUID is required for refresh");
        }

        return new Promise((resolve, reject) => {
            this._static.get(this._uuid, _with).then(({ data }) => {
                let entity = this._resolveResponseData(data);
                for (let key in entity) {
                    this[key] = entity[key];
                }
                resolve(this);
            }).catch(error => reject(error));
        });
    }

    /**
     * Get attribute, using dot notation to get sub-keys (i.e. 'product.name' to get record.product.name)
     * @param {string} key
     */
    getAttribute(key) {
        if (key.indexOf(".") === -1) {
            return this[key];
        }
        return this._gA(this, key.split("."));
    }

    /**
     * Recursive function to get attribute using dot notation
     * @param data {CrudEntity} Data to get attribute from
     * @param keys {string[]} Array of keys to get
     * @returns {*|null} Value of attribute or null if not found
     * @private
     */
    _gA(data, keys = []) {
        if (keys.length === 0) {
            return data;
        }
        let key = keys.shift();
        if (data[key]) {
            return this._gA(data[key], keys);
        }
        return null;
    }
}

export default CrudEntity;
