import moment                    from "moment";
import { createPinia }           from "pinia";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import { createApp }             from "vue";
import InlineSvg                 from "vue-inline-svg";
import "bootstrap/dist/css/bootstrap.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "@/assets/style/custom.scss";
import "@vueform/toggle/themes/default.css";
import "@/assets/style/main.min.css";
import "@/assets/style/global.css";

import App                    from "@/App.vue";
import SmartyRouterLink       from "@/components/global/SmartyRouterLink.vue";
import GlobalDirectives       from "@/globalDirectives";
import componentNameMixin     from "@/shared/Mixins/componentNameMixin.js";
import { initializeApp }      from "@/shared/routeUtils.js";
import emitter, { setLocale } from "@/shared/utils";
import { timeAgo }            from "@/timeUtils";

setLocale(moment);

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);
const app = createApp(App);

// Make the timeAgo function available globally
app.config.globalProperties.$timeAgo  = timeAgo;
app.config.globalProperties.$APP_NAME = import.meta.env.VITE_APP_NAME;
app.config.globalProperties.$API_URL  = import.meta.env.VITE_APP_API_URL;

document.title = import.meta.env.VITE_APP_NAME;

app.use(GlobalDirectives);
app.provide("emitter", emitter);
app.component("SmartyRouterLink", SmartyRouterLink);
app.component("InlineSvg", InlineSvg);
app.use(pinia);
app.mixin(componentNameMixin);
app.config.globalProperties.$emitter = emitter;

initializeApp().then((router) => {
    app.use(router);
    app.mount("#app");
});
